
import pinyin from 'js-pinyin';
/**
 * 文字拼音首字母（大写）
 * @param str
 * @returns {string}
 */
export const getCamelCharsUpperCase = function (str) {
    return pinyin.getCamelChars(str).toUpperCase();
};

export const cleanedStr = (str) => {
    return str.replace(/[^a-zA-Z0-9]/g, '');
};