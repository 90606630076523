import requests from './request'

const baseUrl = '/basedata/product';
const selectorBaseUrl = '/selector';

// "/auth/login" 
export const login = (params) => {
    return requests({
        url: '/auth/login',
        method: 'post',
        params
    })

}
export const getCaptchaCode = () => {
    return requests({ url: '/auth/captcha', method: 'get' })

}
export const logout = () => {
    return requests({
        url: '/auth/logout',
        method: 'post',
    })
}

//品牌
export const reqBrand = (params) => {
    return requests({
        url: selectorBaseUrl + '/brand',
        method: 'get',
        params
    })

}
//拿到分类的接口
export const reqWeightCategory = (data) => {
    return requests({
        url: '/basedata/product/category/query',
        method: 'get',
        params: data,
    })
}
// 拿到分类里面的商品
export const reqCategoryItems = (params) => {
    return requests({
        url: '/basedata/product/query',
        method: 'get',
        params,
    })
}

/**
 * 根据ID查询
 * @param id
 */
export const get = (id) => {
    return requests({
        url: baseUrl,
        method: 'get',
        params: {
            id: id,
        },
    }
    );
}

/**
 * 新增
 * @param data
 */
export const create = (data) => {
    return requests({
        url: baseUrl,
        method: 'post',
        data,
    });
}

/**
 * 修改
 * @param data
 */
export const update = (data) => {
    return requests({
        url: baseUrl,
        method: 'put',
        data,
    });
}